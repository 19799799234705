import React from "react"
import styled from "styled-components"
import { navigate } from "gatsby"

const Nav = styled.div`
  padding: 10px 16px 15px 10px;
  text-align: right;
  z-index: 101;
  ${props =>
    props.position === "absolute"
      ? `
    position: absolute;
    top: 0;
    right: 0;
`
      : `

`}
`

const Button = styled.button`
  font-family: "Space Grotesk", monospace;
  font-size: 20px;
  text-transform: lowercase;
  letter-spacing: 2px;
  transition: 0.1s ease-in;
  line-height: 1;
  background: transparent;
  padding: 8px;
  border: 0;
  cursor: pointer;
  margin: 0 16px;
  border-bottom: 1px solid ${props => (props.isActive ? "#000" : "transparent")};
  color: ${props => (props.isActive ? "#000" : "#444")};
  ${props =>
    props.theme === "dark"
      ? `
          border-bottom: 2px solid ${
            props.isActive ? "rgba(255,255,255, 0.8)" : "transparent"
          };
          color: ${props.isActive ? "#fff" : "rgba(255,255,255, 0.6)"};
          ${
            !props.isActive &&
            `
          &:hover {
            border-bottom: 1px solid rgba(255,255,255, 0.6);
          }
          
          `
          }
        `
      : ""}
`
const NavigateLink = ({ strict, to, label, routes, location, theme }) => {
  const isActive = strict
    ? location?.pathname === to
    : routes
    ? routes.some(p => location?.pathname.includes(p))
    : location?.pathname === to
  return (
    <Button onClick={() => navigate(to)} isActive={isActive} theme={theme}>
      {label}
    </Button>
  )
}
const TopNav = ({ location, theme = "light", position = "absolute" }) => {
  return (
    <Nav theme={theme} position={position}>
      <NavigateLink
        to="/"
        label="About"
        strict
        location={location}
        theme={theme}
      />
      <NavigateLink
        to="/blogs"
        label="Blog"
        theme={theme}
        routes={["/blogs", "/blog"]}
        location={location}
      />
      <NavigateLink
        to="/lab"
        theme={theme}
        label="Lab"
        routes={["/lab"]}
        location={location}
      />
    </Nav>
  )
}
export default TopNav
