import styled, { css } from "styled-components"

export const media = {
  handheld: (...args) => css`
    @media (max-width: 600px) {
      ${css(...args)}
    }
  `,
  tablet: (...args) => css`
    @media (max-width: 769px) {
      ${css(...args)}
    }
  `,
}
export const PageTitle = styled.h1`
  font-size: ${props => props.size || "70px"};
  font-family: "Outfit", sans-serif;
  font-weight: 900;
  padding: 0 0 0 0;
  margin: 0;
  margin-top: ${props => props.marginTop || 0};
  ${media.handheld`
font-size: ${props =>
    props.sizeQuery ? `${props.sizeQuery * 0.7}px` : "50px"};
`}
  ${media.tablet`
font-size: ${props =>
    props.sizeQuery ? `${props.sizeQuery * 0.7}px` : "50px"};
`}
-webkit-text-stroke-width: 2px;
  -webkit-text-stroke-color: #000;
  margin-bottom: 30px;
`

export const AppWrapper = styled.div`
  font-family: "Space Grotesk", monospace;
  text-align: center;
  padding: 0;
  margin: 0;
  color: #fff;
  display: grid;
  grid-template-columns: 100%;
  grid-gap: 0;
  overflow-x: hidden;
  font-size: 20px;
  h2 {
    font-size: 36px;
    margin: 0;
    font-family: inherit;
    font-weight: 600;
  }
  h3 {
    font-family: "Outfit", sans-serif;
    margin: 24px 0;
  }
  a {
    text-decoration: none;
    color: #fff;
    transition: 0.1s ease;
    margin: 5px;
    margin: 4px 16px;
    display: inline-block;
    &:hover {
      border-bottom: 1px dashed #fff;
    }
  }
`

export const SubDescription = styled.p`
  margin: 16px auto;
  font-size: 24px;
  ${props => props.lineHeight && `line-height: ${props.lineHeight};`}
  ${media.handheld`
  padding: 0 10px;
  -webkit-text-stroke-width: 1px;
  font-weight: 700;
`}
`

export const Stage = styled.div`
  margin: 0 auto;
  padding: 10px;
  max-width: 75%;
`

export const Connect = styled.div`
  width: 0;
  margin: 0 auto;
  height: ${props => props.height || "50px"};
  border-left: 1px dashed #fff;
  border-right: 1px dashed #fff;
`

export const SubStage = styled.div`
  padding: 10px 0;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  font-size: ${props => (props.size ? `${props.size}px` : "18px")};
  ${media.handheld`
  font-size: ${props => (props.size ? `${props.size * 0.4}px` : "18px")};
`}
`

export const GridStage = styled.div`
  display: grid;
  justify-content: center;
  grid-template-columns: 46% 8% 46%;
  grid-gap: 1px;
  margin: 0 50px 0 50px;
  ${media.handheld`
  margin: 0;
  grid-template-columns:100%;
  margin: 16px;
`}
`
export const Split = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  ${media.handheld`
  grid-template-columns: 1fr;
  `}
`

export const Section = styled.div`
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;

  ${props =>
    props.flexDirection
      ? `
flex-direction: ${props.flexDirection};
  `
      : ``}
`
